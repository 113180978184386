import React, { useState } from "react";

const Map = () => {
  // let width = 10
  // let length = 10
  // let testMap = Array(length).fill(Array(width).fill('⬜'))

  // console.log(newMap)
  // const [map, setMap] = useState([]);
  const [width, setWidth] = useState(10);
  const [height, setHeight] = useState(10);
  const [map, setMap] = useState([
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],
    ["⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜", "⬜", "⬜","⬜", "⬜"],

  ]);
  const [drawTool, setDrawTool] = useState('⬛')
  const [isDrawing, setIsDrawing] = useState(false);
  const [door, setDoor] = useState(false);
  const [trap, setTrap] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentTile, setCurrentTile] = "";

  // const handleClick = (x, y) => {
  //   let newMap = [...map];
  //   console.log(JSON.stringify(newMap))
  //   console.log(x, y)
  //   console.log(newMap[x,y])
  //   newMap[x][y] = 'w'; // or any other character you want to use for a wall
  //   setMap(newMap);
  // }

  const handleClick = (x, y) => {
    if (door) {
      setDoor(false);
      let newMap = [...map];
      newMap[x][y] = "🔒";
      setMap(newMap);
    } else if (trap) {
      setTrap(false);
      let newMap = [...map];
      newMap[x][y] = "t";
      setMap(newMap);
    } else {
      setStartPos({ x, y });
      setIsDrawing(!isDrawing);
    }
  };

  const handleMouseMove = (x, y) => {
    if (isDrawing) {
      let newMap = [...map];
      newMap[x][y] = "⬛";
      setMap(newMap);
    }
  };

  const handleMouseUp = () => {
    console.log(isDrawing);
    setIsDrawing(false);
  };

  const handleSave = () => {
    // send the map to the server to save it as a JSON file
    fetch("/saveMap", {
      method: "POST",
      body: JSON.stringify({ map }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      });
  };

  const changeBackground = (e) => {
    e.target.style.background = "red";
  };

  const clearBackground = (e) => {
    e.target.style.background = "none";
  };

  return (
    <div>
      <div>
        <label>Width:</label>
        <input
          type="number"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
        <label>Height:</label>
        <input
          type="number"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>
      <div>
        <button onClick={() => setDoor(!door)}>
          {door ? "Cancel Door" : "Place Door"}
        </button>
        <button onClick={() => setTrap(!trap)}>
          {trap ? "Cancel Trap" : "Place Trap"}
        </button>
        
      </div>
      <div>
        <button label="wall">W</button>
      </div>
      <table>
        {map.map((row, i) => (
          <tr key={i}>
            {row.map((cell, j) => (
              <td
                key={j}
                onMouseDown={() => handleClick(i, j)}
                onMouseMove={() => handleMouseMove(i, j)}
                onMouseUp={handleMouseUp}
                onMouseLeave={clearBackground}
                onMouseOver={changeBackground}
                // onClick={() => handleClick(i, j)}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </table>
      <button onClick={handleSave}>Save Map</button>
    </div>
  );
};

export default Map;
