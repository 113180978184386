import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import Map from './map'
// import logo from "./logo.svg";
import io from "socket.io-client";
import "./Root.css";

// let socket;
// if (process.env.NODE_ENV === "development") {
//   socket = io(`http://127.0.0.1:3000`);
// } else {
//   socket = io(`http://111.11.111.111/`);
// }
console.log(process.env);
const { NODE_ENV } = process.env;
const socket =
  NODE_ENV === "development"
    ? io("/")
    : io("https://secretlevellabs.com", {
        path: "/socket",
      });

console.log(socket);

const Root = () => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastPong, setLastPong] = useState(null);
  const [players, setPlayers] = useState([]);
  const [username, setUsername] = useState("");
  const [rooms, setRooms] = useState([]);
  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

     socket.on("quantity_check", data => {
      console.log(data);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("current-players", (data) => {
      setPlayers(data);
    });

    socket.on("pong", () => {
      setLastPong(new Date().toISOString());
    });

    socket.on("available-rooms", (rooms) => {
      setRooms(rooms);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
    };
  }, []);

  const sendPing = () => {
    socket.emit("ping");
  };

  const sendUsername = (username) => {
    socket.emit("set-username", username);
  };

  const getPlayers = () => {
    socket.emit("get-players");
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>Secret Level Labs!</p>
        <p>{NODE_ENV} build</p>
        <p>Connected: {"" + isConnected}</p>
        <p>Last pong: {lastPong || "-"}</p>
        {players.map((player) => (
          <p>{player}</p>
        ))}
        Rooms: {rooms}
        <p>username: {username}</p>
        <input onChange={(e) => setUsername(e.target.value)}></input>
        <button onClick={() => sendUsername(username)}>Set Username</button>
        <button onClick={sendPing}>Send ping</button>
        {/* <button onClick={getPlayers}>Get players</button> */}
        <div style={{padding: '2em'}} id="detail">
            <Outlet />
        </div>
        <Link to="map/1">map</Link>
        <div>
            <Map />
        </div>
      </header>
    </div>
  );
};

export default Root;

  